<ng-container *ngIf="typeMenu == 1">
  <div class="row justify-content-center align-items-center text-center">
      <div class="col-lg-12" *ngIf="row != null && acciones.length > 0">
        <button *ngIf="existeAccion(0)" pButton pRipple [routerLink]="obtenerParametros(0)"
          style="font-size: 13px; padding: .6em; text-align: center; background-color: #800040;" class="mr-2">
          <i class="pi pi-file-edit btn-icon-prepend" style="font-size: 0.9rem; margin-right: .3em;"></i>Editare
        </button>

        <button *ngIf="existeAccion(1)" pButton pRipple class="p-button-danger mr-2"  [routerLink]="obtenerParametros(1)"
          style="font-size: 13px; padding: .6em; text-align: center;">
          <i class="pi pi-trash btn-icon-prepend"  style="font-size: 0.9rem; margin-right: .2em;"></i>Eliminar
        </button>

        <a *ngIf="existeAccion(2)"  class="btn btn-primary btn-xs btn-icon" tooltip="Solventar" style="margin-right: 3px;" [routerLink]="obtenerParametros(2)"  >
          <i class="pi pi-check-circle" style="font-size: 0.8rem"></i>
        </a>
      </div>
  </div>
</ng-container>

<ng-container *ngIf="typeMenu == 2">
  <div class="row justify-content-center align-items-center text-center">
    <div class="col-lg-12" *ngIf="row != null && acciones.length > 0">
      <div class="btn-group"  style="margin-left: 5px;" >
        <button *ngIf="existeAccion(0)" pButton pRipple (click)="emitirId(obtenerParametros(0)[0])"
          style="font-size: 13px; padding: .6em; text-align: center; background-color: #800040;" class="mr-2">
          <i class="pi pi-file-edit btn-icon-prepend" style="font-size: 0.9rem; margin-right: .3em;"></i>Editare
        </button>

        <button *ngIf="existeAccion(1)" pButton pRipple class="p-button-danger mr-2"  (click)="confirmarEliminar(obtenerParametros(1)[0])"
          style="font-size: 13px; padding: .6em; text-align: center;">
          <i class="pi pi-trash btn-icon-prepend"  style="font-size: 0.9rem; margin-right: .2em;"></i>Eliminar
        </button>

        <a *ngIf="existeAccion(2)" type="button" class="btn btn-xs btn-primary btn-icon-text" (click)="solventarPermiso(obtenerParametros(2)[0])">
          <i class="pi pi-check-circle btn-icon-prepend" style="font-size: 0.7rem"></i>
          Solventar
        </a>
        <!-- <div class="btn-group" ngbDropdown role="group" >
          <button  type="button" class="btn btn-primary btn-xs"  style="padding: 0px 3px 0px 3px;" ngbDropdownToggle></button>
          <div class="dropdown-menu" ngbDropdownMenu> -->
              <!-- <li class="divider dropdown-divider"></li> -->
              <!-- <button *ngIf="existeAccion(1)"  ngbDropdownItem class="" style="color: #ff3366"  (click)="confirmSimple(obtenerParametros(1)[0])">
                <i class="pi pi-trash btn-icon-prepend" style="color: #ff3366"  style="font-size: 0.7rem"></i>
                Eliminar
              </button>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</ng-container>

<ng-container>
  <ng-container *ngIf="typeMenu == 3">
    <div class="row justify-content-center align-items-center text-center">
        <div class="col-lg-12" *ngIf="row != null && acciones.length > 0">
          <button *ngFor="let param of this.btnPersonalizados; let i = index" pButton pRipple type="button" [icon]="param.icon" [pTooltip]="param.tooltip" tooltipPosition="top"
          [ngStyle]="{'background-color': param.color, 'border': '1px solid ' + param.color}" style="margin-right: .3em;" (click)="btnPersonalizado(i)"
          [disabled]="param.isDisabled && param.condicionalBtn != null && deshabilitarBtn(row, i)">
          </button>
          <button *ngIf="existeAccion(0)" pButton pRipple type="button" icon="pi pi-file-edit"
          style="margin-right: .3em;" pTooltip="Editar" tooltipPosition="top" (click)="emitirId(obtenerParametros(0)[0])"></button>
          <button *ngIf="existeAccion(1)" pButton pRipple type="button" icon="pi pi-trash" class="p-button-raised p-button-danger" pTooltip="Eliminar" tooltipPosition="top"
          (click)="confirmarEliminar(obtenerParametros(1)[0])"></button>
        </div>
    </div>
  </ng-container>

</ng-container>
